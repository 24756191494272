<template>
  <div class="row mx-2 my-0">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        py-5
        px-4
      "
    >
      <h1 class="mb-5">{{ typeId ? "Edit" : "Create" }} Task Type</h1>
      <alert v-if="loading" class="light-shadow mx-4 mb-4" />
      <form
        v-else
        @submit.prevent="createEditTaskType"
        validate
        class="d-flex flex-column"
      >
        <input-control
          type="horizontal"
          v-model.trim="taskType.type_id"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          ID
        </input-control>
        <input-control
          type="horizontal"
          v-model.trim="taskType.name"
          labelClass="col-md-3 col-lg-2"
          required
          maxLength="255"
        >
          Name
        </input-control>
        <select-control
          type="horizontal"
          :options="userTypeOptions"
          required
          labelClass="col-12 col-md-3 col-lg-2"
          v-model="taskType.user_type"
          >Available For</select-control
        >
        <select-control
          type="horizontal"
          :options="statusOptions"
          required
          labelClass="col-12 col-md-3 col-lg-2"
          v-model="taskType.status"
          >Status</select-control
        >
        <save
          classes="mt-3 save-button btn btn-theme align-self-end"
          :saving="saving"
        >
          {{ taskType.id ? "Update" : "Save" }}
        </save>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      taskType: {
        id: null,
        type_id: null,
        user_type: "all",
        name: "",
        status: "ACTIVE",
      },
      statusOptions: [
        {
          value: "ACTIVE",
          label: "Active",
        },
        { value: "INACTIVE", label: "Inactive" },
      ],
      userTypeOptions: [
        {
          value: "all",
          label: "All",
        },
        { value: "admin_provider", label: "Admins & Providers" },
      ],
      loading: false,
      saving: false,
    };
  },
  async mounted() {
    if (this.typeId) {
      this.loading = true;
      this.taskType = this.taskTypes.filter(
        (type) => type.id == this.typeId
      )[0];
      if (!this.taskType) {
        this.$router.push(`/admin/task-types`);
        return;
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      taskTypes: (state) => state.task.taskTypes.data,
    }),
    typeId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      createTaskType: "task/createTaskType",
      updateTaskType: "task/updateTaskType",
      getTaskTypes: "task/getTaskTypes",
    }),
    createEditTaskType: async function () {
      if (isNaN(this.taskType.type_id)) {
        return Swal.fire({
          title: "Validation Error",
          text: "ID should be a number",
          icon: "error",
        });
      }
      let result;
      this.saving = true;
      if (this.typeId) {
        result = await this.updateTaskType(this.taskType);
      } else {
        result = await this.createTaskType(this.taskType);
      }

      this.saving = false;

      if (result) {
        Swal.fire({
          title: "Success",
          text: `Task type ${
            this.categoryId ? "updated" : "created"
          } successfully`,
          icon: "success",
        });
        this.getTaskTypes();
        this.$router.push(`/admin/task-types`);
      }
    },
  },
};
</script>
